export const palette = {
  background: "#545a63",
  // background:
  //   'linear-gradient(170deg, rgba(255,255,255,1) 0%, rgba(84,90,99,1) 100%)',
  background_empty: "#f7f7f7",
  link: {
    idle: "#adb1b5", // idle text
    hover: "#b09c6e", // hover text
  },
  text: "#adb1b5",
  footer: "#464646",
}
