import { Button, makeStyles } from "@material-ui/core"
import { FC, useContext, useEffect, useState } from "react"
import { TI18nCtx, TTournament } from "../../common"
import { Locker, NoElements } from "../../components"
import { palette } from "../../constants"
import { I18nCtx, localizations } from "../../i18n"
import { tournamentService } from "../../services"

export const TournamentList: FC = () => {
  const { btn, image, item, root } = useStyles()
  const { i18n } = useContext<TI18nCtx>(I18nCtx)

  const [state, setState] = useState<TTournament[] | null>(null)

  const load = async () => {
    const res = await tournamentService.list()
    setState(res)
  }

  useEffect(() => {
    load()
  }, [])

  if (!state) return <Locker />
  if (!state.length) return <NoElements />

  return (
    <div className={root}>
      {state.map((tournament) => (
        <div className={item} key={tournament.id}>
          <a href={`/tournaments/${tournament.id}`}>
            <img
              src={tournament.logo || "/assets/default-img.png"}
              alt="tournament"
              className={image}
            />
          </a>
          <Button href={`/tournaments/${tournament.id}`} className={btn}>
            {localizations.more[i18n]}
          </Button>
        </div>
      ))}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  btn: {
    background: palette.link.idle,
    color: "#fff",
    width: "290px",
    "&:hover": {
      background: palette.link.hover,
    },
  },
  image: {
    width: "290px",
    height: "450px",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "80px",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  root: {
    minHeight: "calc(100vh - 110px - 100px)",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingTop: "30px",
    paddingBottom: "20px",
  },
}))
