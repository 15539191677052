import { makeStyles } from "@material-ui/core"
import { FC, useContext } from "react"
import { TI18nCtx } from "../common"
import { palette } from "../constants"
import { I18nCtx, localizations } from "../i18n"

export const NotFound: FC = () => {
  const { root } = useStyles()
  const { i18n } = useContext<TI18nCtx>(I18nCtx)

  return <h1 className={root}>{localizations.notFound[i18n]}</h1>
}

const useStyles = makeStyles(() => ({
  root: {
    margin: "auto",
    color: palette.text,
  },
}))
