import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "@firebase/firestore"
import { TTournament } from "../common"
import { firestore } from "./firebase"

class TournamentService {
  private readonly collection = "tournament"
  private readonly videoCollection = "video"

  public async list(): Promise<TTournament[]> {
    const ref = collection(firestore, this.collection)
    const q = query(ref, where("published", "==", true), limit(10))

    return getDocs(q).then(({ docs }) =>
      docs
        .map((doc) => ({ ...doc.data(), id: doc.id } as TTournament))
        .sort((a, b) => a.created.seconds - b.created.seconds)
    )
  }

  public async getOne(id: string): Promise<TTournament> {
    const ref = doc(firestore, this.collection, id)
    return getDoc(ref).then<TTournament>(
      (snapshot) =>
        ({
          ...snapshot.data(),
          id: snapshot.id,
        } as TTournament)
    )
  }

  public async getVideos(tournamentId: string): Promise<string[]> {
    const ref = doc(firestore, this.collection, tournamentId)
    const videoRef = collection(firestore, this.videoCollection)
    const q = query(videoRef, where("tournament_id", "==", ref), limit(100))

    return getDocs(q).then(({ docs }) =>
      docs
        .map((doc) => doc.data())
        .sort((a, b) => a.created.seconds - b.created.seconds)
        .map((doc) => doc.url)
    )
  }
}

export const tournamentService = new TournamentService()
