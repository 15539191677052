import { makeStyles } from "@material-ui/core"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"
import { NotFound } from "../components"
import { palette } from "../constants"
import {
  GalleryList,
  Home,
  News,
  NewsList,
  ShopList,
  Tournament,
  TournamentList,
} from "../pages"

export const Main: FC = () => {
  const { root } = useStyles()
  return (
    <main className={root}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="gallery" element={<GalleryList />} />
        <Route path="news" element={<NewsList />} />
        <Route path="news/:id" element={<News />} />
        <Route path="tournaments" element={<TournamentList />} />
        <Route path="tournaments/:id" element={<Tournament />} />
        <Route path="shop" element={<ShopList />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </main>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    background: palette.background,
    display: "flex",
    justifyContent: "center",
    minHeight: "calc(100vh - 110px - 100px)",
  },
}))
