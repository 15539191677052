import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles
} from "@material-ui/core"
import React, { FC, useState } from "react"
import { palette } from "../constants"

type TImageDialogProps = {
  url: string
}
export const ImageDialog: FC<TImageDialogProps> = ({ url }) => {
  const { btn, fullImg, dialogImg, card } = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <div className={card} onClick={() => setOpen(true)}>
        <img src={url} alt="tournament" className={dialogImg} />
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <img src={url} alt="tournament" {...{ loading: "lazy" }} className={fullImg} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)} className={btn}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  btn: {
    background: palette.background,
    color: "#fff",
    "&:hover": {
      background: palette.background,
      color: "#fff",
    },
    fontWeight: "bold",
  },
  card: {
    border: "1px solid black",
    borderRadius: "5px",
    background: "white",
    cursor: "pointer",
  },
  fullImg: {
    width: "100%",
  },
  dialogImg: {
    maxWidth: "400px",
    height: "300px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
}))
