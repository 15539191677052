import { makeStyles } from "@material-ui/core"
import { FC, useEffect, useState } from "react"
import { ImageDialog, Locker, NoElements } from "../../components"
import { galleryService } from "../../services"

export const GalleryList: FC = () => {
  const { root } = useStyles()
  const [state, setState] = useState<string[] | null>(null)

  const load = async () => {
    const res = await galleryService.list()
    setState(res)
  }

  useEffect(() => {
    load()
  }, [])

  if (!state) return <Locker />
  if (!state.length) return <NoElements />

  return (
    <div className={root}>
      {state.map((item, idx) => (
        <ImageDialog url={item} key={idx} />
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "55%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}))
