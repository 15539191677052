import { makeStyles } from "@material-ui/core"
import { FC, useContext } from "react"
import { TI18nCtx } from "../../common"
import { I18nCtx, localizations } from "../../i18n"
import { HeaderLink } from "./link"

export const Menu: FC = () => {
  const { root } = useStyles()
  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  return (
    <nav className={root}>
      <HeaderLink label={localizations.home[i18n]} link="/"></HeaderLink>
      <HeaderLink label={localizations.news[i18n]} link="/news"></HeaderLink>
      <HeaderLink label={localizations.gallery[i18n]} link="/gallery"></HeaderLink>
      <HeaderLink
        label={localizations.tournaments[i18n]}
        link="/tournaments"
      ></HeaderLink>
      <HeaderLink label={localizations.shop[i18n]} link="/shop" isBdg={true}></HeaderLink>
    </nav>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))
