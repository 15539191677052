export const localizations = {
  gallery: {
    eng: "Gallery",
    rus: "Галерея",
  },
  home: {
    eng: "Home",
    rus: "Главная",
  },
  news: {
    eng: "News",
    rus: "Новости",
  },
  tournaments: {
    eng: "Tournaments",
    rus: "Турниры",
  },
  shop: {
    eng: "Shop",
    rus: "Магазин",
  },
  contactUs: {
    eng: "Contact us:",
    rus: "Связаться с нами:",
  },
  rights: {
    eng: "All rights reserved.\nUse and/or distribution of any content without written\nconsent of its respective owner is prohibited.",
    rus: "Все права защищены.\nИспользование и/или распространение\nсодержания сайта без письменного\nсогласия авторов запрещено.",
  },
  mission: {
    eng: {
      __html:
        "<b>Our mission:</b><ul><li>Promote the world’s oldest sport, Olympic Wrestling</li><li>Broadcast live on TV, Internet, mass media</li><li>Organize and produce wrestling tournaments, team duals, camps</li></ul>",
    },
    rus: {
      __html:
        "<b>Наша миссия:</b><ul><li>Популяризация олимпийских видов борьбы как древнейшего вида спорта</li><li>Трансляция через интернет, по ТВ и в СМИ</li><li>Организация турниров, матчевых встреч, сборов</li></ul>",
    },
  },
  more: {
    eng: "More",
    rus: "Подробнее",
  },
  goal: {
    eng: {
      __html:
        '<b>Our goal:</b><ul style="list-style-type:none;"><li>Provide an opportunity for the widest audience possible,</li><li>both amateur and professional, to see and understand all the twists and turns,</li><li>the beauty, clash of tempers, the splendor of classic wrestling.</li></ul>',
    },
    rus: {
      __html:
        '<b>Наша цель:</b><ul style="list-style-type:none;"><li>Предоставить возможность как можно большему количеству зрителей – как</li><li>непосвященных, так и специалистов – увидеть и понять все тонкости, красоту,</li><li>противостояние характеров, интеллекта и благородство этого вида спорта.</li></ul>',
    },
  },
  cookie: {
    eng: "Classicsport uses cookies and collects user data to improve the website and your experience.",
    rus: "Сайт classicsport использует файлы куки и собирает данные пользователя для улучшения сайта и удобства вашего пользования им.",
  },
  cookieButton: {
    eng: "Accept",
    rus: "Принять",
  },
  shopInfo: {
    eng: "For more information and to order, contact Eugeny at +375259617279",
    rus: "Получить подробную информацию и сделать заказ можно по телефону +375259617279 Евгений",
  },
  notFound: {
    eng: "404 page not found",
    rus: "404 страница не найдена",
  },
  noElements: {
    eng: "No elements found",
    rus: "Раздел пока пуст",
  },
}
