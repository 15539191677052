import { StorageReference, list, ref } from "firebase/storage"
import { storage } from "./firebase"

class GalleryService {
  private readonly bucketName: string

  constructor(bucketName = "gallery") {
    this.bucketName = bucketName
  }

  public async list(): Promise<string[]> {
    const listRef = ref(storage, this.bucketName)
    const res = await list(listRef, { maxResults: 100 })

    return res.items.map((item) => this.getUrl(item))
  }

  private getUrl(ref: StorageReference): string {
    return `https://firebasestorage.googleapis.com/v0/b/${ref.bucket}/o/${this.bucketName}%2F${ref.name}?alt=media`
  }
}

export const galleryService = new GalleryService()
export const shopService = new GalleryService("shop")
