import { Grid, makeStyles } from "@material-ui/core"
import { FC, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { EI18n, TI18nCtx, TTournament } from "../../common"
import { Locker } from "../../components"
import { palette } from "../../constants"
import { I18nCtx } from "../../i18n"
import { tournamentService } from "../../services"

type TParams = {
  id: string
}
type TState = TTournament & {
  videos: string[]
}

export const Tournament: FC = () => {
  const { id } = useParams<TParams>()
  const { container, iframe, logo, root, superRoot, text } = useStyles()

  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  const [state, setState] = useState<TState | null>(null)

  const load = async (id: string) => {
    const res = await tournamentService.getOne(id)
    const videos = await tournamentService.getVideos(id)
    setState({ ...res, videos })
  }

  useEffect(() => {
    load(id!)
  }, [id])

  if (!state) return <Locker />

  return (
    <Grid container justifyContent="center" className={superRoot}>
      <Grid item xs={12} md={6} className={root}>
        <img src={state.logo || "/assets/default-img.png"} alt="logo" className={logo} />
      </Grid>
      <Grid item xs={12} md={6} className={root}>
        <div className={text}>
          {state.description[i18n] || state.description[EI18n.en]}
        </div>
      </Grid>
      <Grid item xs={12}>
        {state.videos.length ? (
          <Grid container justifyContent="center" spacing={5} className={container}>
            {state.videos.map((video, idx) => (
              <Grid item xs={12} md={6} key={idx}>
                <div className={iframe} dangerouslySetInnerHTML={{ __html: video }} />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "50px",
    marginBottom: "15px",
  },
  iframe: {
    border: `1px solid ${palette.link}`,
    "& iframe": {
      width: "100%",
      height: "400px",
      marginBottom: "-5px",
    },
  },
  logo: {
    width: "290px",
    height: "450px",
  },
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: "30px",
  },
  superRoot: {
    minHeight: "calc(100vh - 110px - 100px)",
    paddingTop: "30px",
    marginBottom: "15px",
    width: "100%",
  },
  text: {
    color: palette.text,
  },
}))
