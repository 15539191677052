import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  projectId: "classicsport-7e80c",
  storageBucket: "gs://classicsport-7e80c.appspot.com",
}
const app = initializeApp(firebaseConfig)

export const firestore = getFirestore(app)
export const storage = getStorage(app)
