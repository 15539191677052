import { makeStyles, Typography } from "@material-ui/core"
import { FC } from "react"
import { NavLink } from "react-router-dom"
import { palette } from "../../constants"

type TProps = {
  label: string
  link: string
  isBdg?: boolean
}
export const HeaderLink: FC<TProps> = ({ label, link, isBdg }) => {
  const { root, bdg } = useStyles()
  return (
    <NavLink
      to={link}
      className={root}
      style={({ isActive }) => {
        return {
          color: isActive ? palette.link.hover : palette.link.idle,
        }
      }}
    >
      <Typography variant={"h5"}>{label}</Typography>
      {isBdg && <div className={bdg}>BETA</div>}
    </NavLink>
  )
}

const useStyles = makeStyles(() => ({
  bdg: {
    backgroundColor: "#3cb55b",
    borderRadius: "5px",
    color: "#ffffff",
    display: "inline-block",
    fontFamily: "Arial, sans-serif",
    fontSize: "10px",
    height: "20px",
    marginLeft: "3px",
    padding: "4px 6px",
  },
  root: {
    color: palette.link.idle,
    display: "flex",
    position: "relative",
    padding: "0 12px 0 24px",
    textDecoration: "none",
    "&:hover": {
      color: palette.link.hover,
    },
  },
}))
