import { makeStyles } from "@material-ui/core"
import { FC, useContext } from "react"
import { TI18nCtx } from "../../common"
import { ImageScroll } from "../../components/image-scroll"
import { palette } from "../../constants"
import { I18nCtx, localizations } from "../../i18n"

export const Home: FC = () => {
  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  const {
    author,
    container,
    main,
    mainImg,
    mission,
    logoImg,
    title,
    divider,
    dividerContainer,
    center,
  } = useStyles()
  return (
    <main className={container}>
      <div className={main}>
        <div style={{ width: "280px" }}>
          <img src="/assets/main.jpg" alt="main" className={mainImg} />
        </div>
        <p className={title}>CLASSIC SPORT</p>
      </div>
      <div className={center}>
        <ImageScroll />
      </div>
      <div className={dividerContainer}>
        <h1>Exclusive trophies for the best wrestlers in top tournaments</h1>
        <div className={divider} />
      </div>
      <div className={mission}>
        <span dangerouslySetInnerHTML={localizations.mission[i18n]} />
        <span dangerouslySetInnerHTML={localizations.goal[i18n]} />
      </div>
      <img src="/assets/logo.png" alt="logo" className={logoImg} />
      <p className={author}>by Classic Sport LLC</p>
    </main>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 110px - 100px)",
    width: "100%",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  mainImg: {
    height: "200px",
  },
  logoImg: {
    alignSelf: "center",
    width: "290px",
  },
  title: {
    color: palette.link.hover,
    textAlign: "center",
    fontSize: "100px",
    lineHeight: "1.55 rem",
    fontWeight: "bold",
  },
  mission: {
    display: "flex",
    flexWrap: "wrap",
    color: palette.link.hover,
    justifyContent: "center",
    fontSize: "21px",
    whiteSpace: "pre-line",
  },
  author: {
    color: palette.link.hover,
    textAlign: "center",
    fontSize: "21px",
    bottom: "0%",
  },
  divider: {
    width: "90%",
    height: "2px",
    backgroundColor: palette.link.hover,
    marginBottom: "50px",
  },
  dividerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: palette.link.hover,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "50px",
  },
}))
