import { makeStyles } from "@material-ui/core"
import { FC, useContext, useEffect, useState } from "react"
import { TI18nCtx } from "../../common"
import { ImageDialog, Locker, NoElements } from "../../components"
import { palette } from "../../constants"
import { I18nCtx, localizations } from "../../i18n"
import { shopService } from "../../services"

export const ShopList: FC = () => {
  const { container, root, text } = useStyles()

  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  const [state, setState] = useState<string[] | null>(null)

  const load = async () => {
    const res = await shopService.list()
    setState(res)
  }

  useEffect(() => {
    load()
  }, [])

  if (!state) return <Locker />
  if (!state.length) return <NoElements />

  return (
    <div className={root}>
      <h1 className={text}>{localizations.shopInfo[i18n]}</h1>
      <div className={container}>
        {state.map((item) => (
          <ImageDialog url={item} />
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "55%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    marginBottom: "30px",
    color: palette.link.hover,
  },
}))
