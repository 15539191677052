import { CircularProgress, makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { palette } from "../constants"

export const Locker: FC = () => {
  const { circle, root } = useStyles()
  return (
    <div className={root}>
      <CircularProgress size={50} className={circle} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000, // needs to be more that MUI modal, currently 1300
  },
  circle: {
    color: palette.link.hover,
  },
}))
