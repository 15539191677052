import { makeStyles } from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { palette } from "../constants"

const step = 370
const arr = new Array(13).fill(null)
const images = arr.map((_, idx) => `/assets/scroll/wrestling${idx + 1}.jpg`)

export const ImageScroll = () => {
  const { img, root, btn, block } = useStyles()

  const scrollLeft = () => {
    const left = document.getElementById("block")
    left?.scrollBy(step, 0)
  }

  const scrollRight = () => {
    const right = document.getElementById("block")
    right?.scrollBy(-step, 0)
  }

  return (
    <div className={root}>
      <button className={btn} onClick={scrollRight}>
        <ChevronLeftIcon />
      </button>
      <div className={block} id="block">
        {images.map((item, idx) => (
          <img className={img} src={item} alt="image" key={idx} />
        ))}
      </div>
      <button className={btn} onClick={scrollLeft}>
        <ChevronRightIcon />
      </button>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  block: {
    width: "80%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
    scrollBehavior: "smooth",
  },
  img: {
    minWidth: "350px",
    height: "400px",
    margin: "0px 10px",
    cursor: "pointer",
    overflow: "hidden",
  },
  btn: {
    margin: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: palette.text,
    backgroundColor: palette.footer,
    fontSize: "25px",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      color: palette.link.hover,
    },
  },
}))
