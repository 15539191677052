import { CssBaseline } from "@material-ui/core"
import Cookies from "js-cookie"
import React, { useState } from "react"
import { BrowserRouter } from "react-router-dom"
import { EI18n } from "../common"
import { Cookie } from "../components"
import { I18nCtx } from "../i18n"
import { Footer } from "./footer"
import { Header } from "./header"
import { Main } from "./main"

export const App: React.FC = () => {
  const [i18n, setI18n] = useState<EI18n>(
    (Cookies.get("localization") as EI18n) || EI18n.en
  )

  const setI18nCookie = (state: EI18n) => {
    Cookies.set("localization", state)
    setI18n(state)
  }

  return (
    <I18nCtx.Provider value={{ i18n, setI18n: setI18nCookie }}>
      <CssBaseline />
      <BrowserRouter>
        <Header />
        <Main />
      </BrowserRouter>
      <Footer />
      <Cookie />
    </I18nCtx.Provider>
  )
}
