import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "@firebase/firestore"
import { TNews } from "../common"
import { firestore } from "./firebase"

class NewsService {
  private readonly route = "/news"
  private readonly collection = "news"

  public async list(): Promise<TNews[]> {
    const ref = collection(firestore, this.collection)
    const q = query(ref, where("published", "==", true), limit(10))

    return getDocs(q).then(({ docs }) =>
      docs
        .map((doc) => ({ ...doc.data(), id: doc.id } as TNews))
        .sort((a, b) => a.created.seconds - b.created.seconds)
    )
  }

  public async getOne(id: string): Promise<TNews> {
    const ref = doc(firestore, this.collection, id)
    return getDoc(ref).then<TNews>(
      (snapshot) =>
        ({
          ...snapshot.data(),
          id: snapshot.id,
        } as TNews)
    )
  }
}

export const newsService = new NewsService()
