import { makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { palette } from "../../constants"
import { ChangeLanguage } from "./change-language"
import { Menu } from "./menu"

export const Header: FC = () => {
  const { left, root, size } = useStyles()
  return (
    <>
      <div className={size} />
      <header className={root}>
        <div className={left}>
          <Menu />
          <ChangeLanguage />
        </div>
      </header>
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    background: palette.background,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    height: "100px",
    width: "100%",
  },
  left: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  size: {
    height: "100px",
    width: "100%",
  },
}))
