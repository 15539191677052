import { makeStyles } from "@material-ui/core"
import { FC, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { EI18n, TI18nCtx, TNews } from "../../common"
import { Locker } from "../../components"
import { palette } from "../../constants"
import { I18nCtx } from "../../i18n"
import { newsService } from "../../services"

type TParams = {
  id: string
}
export const News: FC = () => {
  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  const { root } = useStyles()
  const { id } = useParams<TParams>()
  const [state, setState] = useState<TNews | null>(null)

  const load = async (id: string) => {
    const res = await newsService.getOne(id)
    setState(res)
  }

  useEffect(() => {
    load(id!)
  }, [id])

  if (!state) return <Locker />
  return (
    <div
      className={root}
      dangerouslySetInnerHTML={{
        __html: state.text[i18n] || state.text[EI18n.en],
      }}
    />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 110px - 100px)",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 30px 20px",
    fontSize: "20px",
    color: palette.text,
  },
}))
