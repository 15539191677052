import { makeStyles, Typography } from "@material-ui/core"
import YouTubeIcon from "@material-ui/icons/YouTube"
import React, { useContext } from "react"
import { TI18nCtx } from "../common"
import { palette } from "../constants"
import { I18nCtx, localizations } from "../i18n"

export const Footer: React.FC = () => {
  const { footerContent, footerItem, footerItemIcon, root, stub, text, youtubeIcon } =
    useStyles()
  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  const yearNow = new Date().getFullYear()
  return (
    <>
      <div className={stub} />
      <footer className={root}>
        <div className={footerContent}>
          <div className={footerItem}>
            <Typography className={text}>{localizations.contactUs[i18n]}</Typography>
            <Typography
              className={text}
              component="a"
              href={"mailto:info@classicsport.pw"}
            >
              info@classicsport.pw
            </Typography>
            <Typography component="a" href="tel:+1 718 509 5655" className={text}>
              +1 718 509 5655
            </Typography>
          </div>
          <a
            className={footerItemIcon}
            href={"https://www.youtube.com/channel/UCOE3vmiJp1pUxCxCPOYkkQw"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon className={youtubeIcon} />
          </a>
          <div className={footerItem}>
            <Typography className={text}>
              © {yearNow}. {localizations.rights[i18n]}
            </Typography>
          </div>
        </div>
      </footer>
    </>
  )
}

const youtubeSize: number = 57
const useStyles = makeStyles(() => ({
  "@global": {
    body: {
      position: "relative",
    },
  },
  stub: {
    height: "110px",
  },
  root: {
    height: "110px",
    background: palette.footer,
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    padding: "15px 0",
  },
  footerItem: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footerItemIcon: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  youtubeIcon: {
    color: palette.link.hover,
    width: youtubeSize,
    height: youtubeSize,
  },
  text: {
    color: palette.link.hover,
    fontSize: "15px",
    textAlign: "center",
  },
  footerContent: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "1200px",
    margin: "0 auto",
  },
}))
