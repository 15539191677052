import { Button } from "@material-ui/core"
import { FC, useContext } from "react"
import { EI18n, TI18nCtx } from "../../common"
import { palette } from "../../constants"
import { I18nCtx } from "../../i18n"

export const ChangeLanguage: FC = () => {
  const { i18n, setI18n } = useContext<TI18nCtx>(I18nCtx)
  return (
    <>
      <Button
        style={{
          color: i18n === EI18n.en ? palette.link.hover : palette.link.idle,
        }}
        onClick={() => setI18n(EI18n.en)}
      >
        ENG
      </Button>
      <Button
        style={{
          color: i18n === EI18n.ru ? palette.link.hover : palette.link.idle,
        }}
        onClick={() => setI18n(EI18n.ru)}
      >
        РУС
      </Button>
    </>
  )
}
