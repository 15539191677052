import { makeStyles } from "@material-ui/core"
import { FC, useContext, useEffect, useState } from "react"
import { EI18n, TI18nCtx, TNews } from "../../common"
import { Locker, NoElements } from "../../components"
import { palette } from "../../constants"
import { I18nCtx } from "../../i18n"
import { newsService } from "../../services"

export const NewsList: FC = () => {
  const { i18n } = useContext<TI18nCtx>(I18nCtx)
  const { image, item, root, title } = useStyles()
  const [state, setState] = useState<TNews[] | null>(null)

  const load = async () => {
    const res = await newsService.list()
    setState(res)
  }

  useEffect(() => {
    load()
  }, [])

  if (!state) return <Locker />
  if (!state.length) return <NoElements />

  return (
    <div className={root}>
      {state.map((news) => (
        <a className={item} key={news.id} href={`/news/${news.id}`}>
          <div>
            <img
              src={news.logo || "/assets/default-img.png"}
              alt="news"
              className={image}
            />
          </div>
          <div className={title}>
            <p>{news.title[i18n] || news.title[EI18n.en]}</p>
          </div>
        </a>
      ))}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  image: {
    width: "200px",
    margin: "0 80px",
  },
  item: {
    display: "flex",
    textDecorationColor: palette.text,
    marginTop: "30px",
  },
  root: {
    minHeight: "calc(100vh - 110px - 100px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
    paddingBottom: "20px",
  },
  title: {
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "bold",
    color: palette.text,
    margin: "0 80px",
    "&:hover": {
      color: palette.text,
    },
  },
}))
