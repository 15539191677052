import { FC, useContext } from "react"
import { CookieConsent } from "react-cookie-consent"
import { TI18nCtx } from "../common"
import { palette } from "../constants"
import { I18nCtx, localizations } from "../i18n"

export const Cookie: FC = () => {
  const { i18n } = useContext<TI18nCtx>(I18nCtx)

  return (
    <CookieConsent
      location="bottom"
      buttonText={localizations.cookieButton[i18n]}
      style={{
        background: palette.footer,
        color: palette.link.hover,
        fontSize: "20px",
        fontWeight: "bold",
        justifyContent: "center",
      }}
      buttonStyle={{
        background: palette.link.hover,
        color: palette.footer,
        marginTop: "0px",
        borderRadius: "10%",
        fontSize: "20px",
        fontWeight: "bold",
      }}
      contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
    >
      {localizations.cookie[i18n]}
    </CookieConsent>
  )
}
